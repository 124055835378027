<template>
  <div>
    <div class="page-header">
      <h1>{{ $t("metadata") }}</h1>
      <b-button
        v-if="userHasRoleSuperAdmin"
        type="is-primary"
        icon-left="table"
        @click="showExportModal = true"
      >
        {{ $t("export_csv") }}
      </b-button>
    </div>

    <div class="card">
      <div class="card-content pt-3">
        <b-table
          ref="table"
          :current-page="page"
          :data="xviewmetadata"
          :loading="loading"
          :total="total"
          backend-pagination
          backend-sorting
          :default-sort="order.by"
          :default-sort-direction="order.direction"
          hoverable
          paginated
          pagination-simple
          pagination-size="is-small"
          per-page="30"
          custom-detail-row
          detail-key="id"
          detailed
          :opened-detailed="detailedRows"
          :show-detail-icon="true"
          @page-change="(e) => (page = e)"
          @sort="sort"
        >
          <template #empty>
            {{ loading ? $t("loading") : $t("no_matching_results") }}
          </template>

          <b-table-column
            :visible="userHasRoleSuperAdmin || userHasRolePartner"
            :label="$t('cloud_customer')"
          >
            <template v-slot="props">
              {{ props.row.client.name }}
            </template>
          </b-table-column>

          <b-table-column
            :label="$t('project_date')"
            field="projectDate"
            sortable
          >
            <template v-slot="props">
              {{ props.row.projectDate | date }}
            </template>
          </b-table-column>

          <b-table-column
            :label="$t('project_name')"
            field="projectName"
            sortable
          >
            <template v-slot="props">
              {{ props.row.projectName }}
            </template>
          </b-table-column>

          <b-table-column :label="$tc('user')">
            <template v-slot="props">
              {{ props.row.user ? props.row.user.name : "–" }}
            </template>
          </b-table-column>

          <b-table-column :label="$tc('product')">
            <template v-slot="props">
              {{ props.row.serialNumber }}<br />
              <small v-if="props.row.product">
                {{ props.row.product.name }}
              </small>
            </template>
          </b-table-column>

          <b-table-column :label="$t('approximate_length')" numeric>
            <template v-slot="props">
              {{
                props.row.approximateLengthCalculated
                  | distance(props.row.systemOfMeasurement.name)
              }}
            </template>
          </b-table-column>

          <b-table-column :label="$t('average_scaling')" numeric>
            <template v-slot="props">
              {{ props.row.averageScaling | percentage(2) }}
            </template>
          </b-table-column>

          <b-table-column :label="$t('spread')">
            <template v-slot="props">
              {{ $t("xy") }}:
              {{
                props.row.observedSpreadXYCalculated
                  | distance(props.row.systemOfMeasurement.name, 2)
              }}<br />
              {{ $t("z") }}:
              {{
                props.row.observedSpreadZCalculated
                  | distance(props.row.systemOfMeasurement.name, 2)
              }}
            </template>
          </b-table-column>

          <b-table-column :label="$t('spread_percentage')">
            <template v-slot="props">
              {{ $t("xy") }}:
              {{ props.row.observedSpreadPercentageXY | percentage(2) }}<br />
              {{ $t("z") }}:
              {{ props.row.observedSpreadPercentageZ | percentage(2) }}
            </template>
          </b-table-column>

          <b-table-column :label="$t('total_runs')">
            <template v-slot="props">
              {{ $t("ab") }}: {{ props.row.numberOfRunsAB }}<br />
              {{ $t("ba") }}: {{ props.row.numberOfRunsBA }}<br />
              <small>
                {{ $t("total") }}: {{ props.row.totalNumberOfRuns }}
              </small>
            </template>
          </b-table-column>

          <b-table-column :label="$t('upload_date')" field="createdAt" sortable>
            <template v-slot="props">
              {{ props.row.createdAt | date }}<br />
              <small>{{ props.row.createdAt | time }}</small>
            </template>
          </b-table-column>

          <b-table-column
            :visible="userHasRoleSuperAdmin"
            :label="$t('version')"
            field="xtraction_version"
            sortable
          >
            <template v-slot="props">
              <small>
                XT {{ props.row.xTractionVersion }}<br />
                XV {{ props.row.xViewVersion }}
              </small>
            </template>
          </b-table-column>

          <b-table-column>
            <template v-slot="props">
              <b-button
                v-if="
                  props.row.cloudBackup &&
                  props.row.client.supportAndAnalysisConsent
                "
                tag="a"
                icon-left="cloud-download-outline"
                rounded
                @click="download(props.row)"
              ></b-button>
            </template>
          </b-table-column>

          <template slot="detail" slot-scope="props">
            <tr v-for="item in props.row.items" :key="item.id">
              <td
                :colspan="userHasRoleSuperAdmin || userHasRolePartner ? 6 : 5"
              ></td>

              <td class="has-text-right">
                {{
                  item.approximateLengthCalculated
                    | distance(item.systemOfMeasurement.name)
                }}
              </td>

              <td class="has-text-right">
                {{ item.averageScaling | percentage(2) }}
              </td>

              <td>
                {{ $t("xy") }}:
                {{
                  item.observedSpreadXYCalculated
                    | distance(item.systemOfMeasurement.name, 2)
                }}
                <br />
                {{ $t("z") }}:
                {{
                  item.observedSpreadZCalculated
                    | distance(item.systemOfMeasurement.name, 2)
                }}
              </td>

              <td>
                {{ $t("xy") }}:
                {{ item.observedSpreadPercentageXY | percentage(2) }}<br />
                {{ $t("z") }}:
                {{ item.observedSpreadPercentageZ | percentage(2) }}
              </td>

              <td>
                {{ $t("ab") }}: {{ item.numberOfRunsAB }}<br />
                {{ $t("ba") }}: {{ item.numberOfRunsBA }}<br />
                <small> {{ $t("total") }}: {{ item.totalNumberOfRuns }} </small>
              </td>

              <td>
                {{ item.createdAt | date }}<br />
                <small>{{ item.createdAt | time }}</small>
              </td>

              <td v-if="userHasRoleSuperAdmin">
                <small>
                  XT {{ item.xTractionVersion }}<br />
                  XV {{ item.xViewVersion }}
                </small>
              </td>

              <td>
                <b-button
                  v-if="item.cloudBackup"
                  tag="a"
                  icon-left="cloud-download-outline"
                  rounded
                  @click="download(item)"
                ></b-button>
              </td>
            </tr>
          </template>
        </b-table>
      </div>
    </div>

    <b-modal v-model="showExportModal" :width="400" scroll="keep">
      <ExportModal></ExportModal>
    </b-modal>
  </div>
</template>

<script>
import dayjs from "dayjs";
import { mapActions, mapGetters, mapState } from "vuex";
import CompanyCache from "../../helpers/CompanyCache";
import UserCache from "../../helpers/UserCache";
import ExportModal from "./partials/ExportModal";

export default {
  name: "MetadataIndex",
  components: {
    ExportModal,
  },
  data() {
    return {
      loading: true,
      page: 1,
      total: null,
      showExportModal: false,
      detailedRows: [],
      xviewmetadata: [],
      companyCache: new CompanyCache(),
      userCache: new UserCache(),
      order: {
        by: "createdAt",
        direction: "desc",
      },
    };
  },
  computed: {
    ...mapGetters(["userHasRolePartner", "userHasRoleSuperAdmin"]),
    ...mapState({
      user: (state) => state.auth.user,
    }),
  },
  watch: {
    page() {
      this.load();
    },
    detailedRows(value) {
      value.forEach((id) => {
        const metadata = this.xviewmetadata.find((m) => m.id === id);
        if (metadata.items) {
          return;
        }
        this.loadDetails(id);
      });
    },
  },
  mounted() {
    this.load();
  },
  methods: {
    ...mapActions([
      "downloadMetadata",
      "getAllMetadata",
      "getMetadata",
      "getUser",
    ]),
    async load() {
      this.loading = true;

      this.getMetadata({
        page: this.page,
        bestScalingRun: true,
        orderBy: this.order.by,
        orderDirection: this.order.direction,
      }).then(async ({ items, total }) => {
        this.xviewmetadata = items;
        this.total = total;
        this.loading = false;
      });
    },
    loadDetails(id) {
      this.loading = true;
      const metadata = this.xviewmetadata.find((m) => m.id === id);
      this.getAllMetadata({
        bestScalingRun: false,
        serialNumber: metadata.serialNumber,
        projectDate: dayjs(metadata.projectDate).format("YYYY-MM-DD"),
        downloadDate: metadata.downloadDate,
      }).then(async (items) => {
        const index = this.xviewmetadata.findIndex((m) => m.id === id);
        this.$set(this.xviewmetadata[index], "items", items);

        this.loading = false;
      });
    },
    async download(metadata) {
      await this.downloadMetadata(metadata.id).then((res) => {
        const filename = res.headers["content-disposition"]
          .split("filename=")[1]
          .split(";")[0]
          .replaceAll('"', "");
        const href = URL.createObjectURL(res.data);
        const link = document.createElement("a");
        link.href = href;
        link.setAttribute("download", filename);
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
        URL.revokeObjectURL(href);
      });
    },
    sort(orderBy, orderDirection) {
      this.order.by = orderBy;
      this.order.direction = orderDirection;
      this.load();
    },
  },
};
</script>
