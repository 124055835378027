import store from "../store";

export default class UserCache {
  users = [];

  async get(id) {
    let user = this.users.find((c) => c.id === id);

    if (!user) {
      user = await store.dispatch("getUser", id);
      this.users.push(user);
    }

    return user;
  }
}
