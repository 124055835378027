<template>
  <div class="card">
    <b-loading v-model="loading"></b-loading>

    <div class="card-header">
      <div class="card-header-title">
        {{ $t("export_csv") }}
      </div>
    </div>
    <div class="card-content">
      <form class="mt-2">
        <b-field :label="$t('period')">
          <CustomDatePicker
            v-model="filter.from"
            :max="filter.till"
            :horizontal="false"
            :required="false"
            :placeholder="$t('from')"
            :max-today="true"
            class="mr-1"
          ></CustomDatePicker>
          <CustomDatePicker
            v-model="filter.till"
            :min="filter.from"
            :horizontal="false"
            :required="false"
            :placeholder="$t('till')"
            :max-today="true"
          ></CustomDatePicker>
        </b-field>

        <div class="mb-3">
          <b-button
            size="is-small"
            class="mr-1 mb-1"
            @click="setPeriod('last30Days')"
          >
            Last 30 days
          </b-button>
          <b-button
            size="is-small"
            class="mr-1 mb-1"
            @click="setPeriod('currentYear')"
          >
            Current year
          </b-button>
          <b-button
            size="is-small"
            class="mr-1 mb-1"
            @click="setPeriod('lastMonth')"
          >
            Last month
          </b-button>
          <b-button
            size="is-small"
            class="mr-1 mb-1"
            @click="setPeriod('lastQuarter')"
          >
            Last quarter
          </b-button>
          <b-button
            size="is-small"
            class="mr-1 mb-1"
            @click="setPeriod('lastYear')"
          >
            Last year
          </b-button>
        </div>

        <b-field v-if="userHasRoleSuperAdmin" :label="$tc('cloud_customer')">
          <b-dropdown v-model="filter.clients" expanded multiple>
            <template #trigger="{ active }">
              <b-button
                :label="clientLabel"
                :icon-right="active ? 'menu-up' : 'menu-down'"
                expanded
              />
            </template>
            <b-dropdown-item @click="filter.clients = []">
              {{ $t("all") }}
            </b-dropdown-item>
            <b-dropdown-item v-for="c in clients" :key="c.id" :value="c">
              {{ c.name }}
            </b-dropdown-item>
          </b-dropdown>
        </b-field>

        <b-field :label="$t('product_serial_number')">
          <b-dropdown v-model="filter.serialNumbers" expanded multiple>
            <template #trigger="{ active }">
              <b-button
                :label="deviceLabel"
                :icon-right="active ? 'menu-up' : 'menu-down'"
                expanded
              />
            </template>
            <b-dropdown-item @click="filter.serialNumbers = []">
              {{ $t("all") }}
            </b-dropdown-item>
            <b-dropdown-item v-for="d in serialNumbers" :key="d" :value="d">
              {{ d }}
            </b-dropdown-item>
          </b-dropdown>
        </b-field>

        <b-field :label="$tc('product_type')">
          <b-dropdown v-model="filter.products" expanded multiple>
            <template #trigger="{ active }">
              <b-button
                :label="productLabel"
                :icon-right="active ? 'menu-up' : 'menu-down'"
                expanded
              />
            </template>
            <b-dropdown-item @click="filter.products = []">
              {{ $t("all") }}
            </b-dropdown-item>
            <b-dropdown-item v-for="p in products" :key="p.id" :value="p">
              {{ p.name }}
            </b-dropdown-item>
          </b-dropdown>
        </b-field>

        <b-field :label="$t('pipeline_owner')">
          <b-dropdown v-model="filter.pipelineOwners" expanded multiple>
            <template #trigger="{ active }">
              <b-button
                :label="pipelineOwnerLabel"
                :icon-right="active ? 'menu-up' : 'menu-down'"
                expanded
              />
            </template>
            <b-dropdown-item @click="filter.pipelineOwners = []">
              {{ $t("all") }}
            </b-dropdown-item>
            <b-dropdown-item v-for="p in pipelineOwners" :key="p" :value="p">
              {{ p }}
            </b-dropdown-item>
          </b-dropdown>
        </b-field>

        <b-field :label="$t('delimiter')" label-for="delimiter">
          <b-select id="delimiter" v-model="filter.delimiter">
            <option value=",">{{ $t("comma") }}</option>
            <option value=";">{{ $t("semicolon") }}</option>
          </b-select>
        </b-field>

        <b-field v-if="userHasRoleSuperAdmin">
          <b-checkbox v-model="filter.includeDoubleUploads">
            {{ $t("include_double_uploads") }}
          </b-checkbox>
        </b-field>

        <b-button
          type="is-primary"
          class="mt-3"
          icon-left="table"
          :loading="submitting"
          @click="download()"
        >
          {{ $t("export_csv") }}
        </b-button>
      </form>
    </div>
  </div>
</template>

<script>
import dayjs from "dayjs";
import { sortBy } from "lodash-es";
import { mapActions, mapGetters } from "vuex";
import CustomDatePicker from "@/components/forms/CustomDatePicker.vue";

export default {
  components: { CustomDatePicker },
  data() {
    return {
      loading: true,
      submitting: false,
      clients: [],
      serialNumbers: [],
      products: [],
      pipelineOwners: [],
      filter: {
        from: null,
        till: null,
        clients: [],
        serialNumbers: [],
        products: [],
        pipelineOwners: [],
        delimiter: ",",
        includeDoubleUploads: false,
      },
    };
  },
  computed: {
    ...mapGetters(["userHasRoleSuperAdmin"]),
    clientLabel() {
      if (this.filter.clients.length === 0) {
        return this.$t("all");
      }
      return this.filter.clients.map((c) => c.name).join(", ");
    },
    deviceLabel() {
      if (this.filter.serialNumbers.length === 0) {
        return this.$t("all");
      }
      return this.filter.serialNumbers.join(", ");
    },
    productLabel() {
      if (this.filter.products.length === 0) {
        return this.$t("all");
      }
      return this.filter.products.map((p) => p.name).join(", ");
    },
    pipelineOwnerLabel() {
      if (this.filter.pipelineOwners.length === 0) {
        return this.$t("all");
      }
      return this.filter.pipelineOwners.join(", ");
    },
  },
  async mounted() {
    this.getMetadataFilters().then((filterData) => {
      this.clients = sortBy(filterData.clients, ["name"]);
      this.products = sortBy(filterData.products, ["name"]);
      this.pipelineOwners = filterData.pipeOwners.sort();
      this.serialNumbers = filterData.serialNumbers.sort();
      this.loading = false;
    });
  },
  methods: {
    ...mapActions(["exportMetadata", "getMetadataFilters"]),
    setPeriod(period) {
      switch (period) {
        case "last30Days":
          this.filter.from = dayjs().subtract(30, "days").format("YYYY-MM-DD");
          this.filter.till = dayjs().format("YYYY-MM-DD");
          break;
        case "currentYear":
          this.filter.from = dayjs().startOf("year").format("YYYY-MM-DD");
          this.filter.till = dayjs().format("YYYY-MM-DD");
          break;
        case "lastMonth":
          this.filter.from = dayjs()
            .subtract(1, "month")
            .startOf("month")
            .format("YYYY-MM-DD");
          this.filter.till = dayjs()
            .subtract(1, "month")
            .endOf("month")
            .format("YYYY-MM-DD");
          break;
        case "lastQuarter":
          this.filter.from = dayjs()
            .subtract(3, "month")
            .startOf("month")
            .format("YYYY-MM-DD");
          this.filter.till = dayjs()
            .subtract(1, "month")
            .endOf("month")
            .format("YYYY-MM-DD");
          break;
        case "lastYear":
          this.filter.from = dayjs()
            .subtract(1, "year")
            .startOf("month")
            .format("YYYY-MM-DD");
          this.filter.till = dayjs()
            .subtract(1, "month")
            .endOf("month")
            .format("YYYY-MM-DD");
          break;
      }
    },
    async download() {
      this.submitting = true;

      const filter = {
        from: this.filter.from,
        till: this.filter.till,
        clients: this.filter.clients.map((client) => client.id),
        serialNumbers: this.filter.serialNumbers,
        products: this.filter.products.map((product) => product.id),
        pipelineOwners: this.filter.pipelineOwners,
        delimiter: this.filter.delimiter,
        includeDoubleUploads: this.filter.includeDoubleUploads,
      };

      await this.exportMetadata(filter)
        .then((res) => {
          const date = dayjs().format("YYMMDD");
          const filename = `Reduct_cloud_export_${date}.csv`;
          const href = URL.createObjectURL(res.data);
          const link = document.createElement("a");
          link.href = href;
          link.setAttribute("download", filename);
          document.body.appendChild(link);
          link.click();
          document.body.removeChild(link);
          URL.revokeObjectURL(href);
        })
        .catch((error) => {
          if (error.message === "NO_CONTENT") {
            this.$buefy.toast.open({
              message: this.$t("no_matching_results"),
              type: "is-danger",
            });
          }
        })
        .finally(() => {
          this.submitting = false;
        });
    },
  },
};
</script>
